import { createActions } from 'reduxsauce';

export interface IPageState {
  data: IPageData;
  allPages: IPageData;
  isLoading: boolean;
  error: string;
}
export interface IPageData {
  pages: IPage[];
  pageCreated: IPageCreated;
}
export interface IPageCreated {
  query_string: string;
  page_name: string;
  page_id: number;
}
export interface IPage {
  recipient_ids: string[];
  client_id: string;
  created_date: string;
  de_page: string;
  desc: string;
  donation_amounts: number[];
  id: number;
  impact_score_visibility: boolean;
  is_enabled: boolean;
  page_misc: {
    page_background: string | null;
    page_color_text: string | null;
  };
  page_type: string;
  processing_redirect: string;
  query_str: string;
  value: string;
  status: string;
  page_cta: string;
}

export type BodyCreateProps = {
  value: string;
  query_str: string;
  desc: string;
  donation_amounts: number[];
  client_id: string;
  page_cta: string;
  page_misc: {
    page_background: string | null;
    page_color_text: string | null;
  };
  impact_score_visibility: boolean;
  is_enabled: boolean;
  recipient_ids: string[];
};

export type BodyUpdateProps = {
  client_id: string;
  page: {
    value: string;
    query_str: string;
    desc: string;
    donation_amounts: number[];
    client_id: string;
    page_cta: string;
    page_misc: {
      page_background: string | null;
      page_color_text: string | null;
    };
    impact_score_visibility: boolean;
    is_enabled: boolean;
    id: number;
  };
};

export type IFile = {
  name: string;
  size: number;
  type: string;
};

export interface GetSignedUrlConfig {
  action: 'read' | 'write' | 'delete' | 'resumable';
  expires: number | string;
  version?: 'v2' | 'v4';
  cname?: string;
  contentMd5?: string;
  contentType?: string;
  promptSaveAs?: string;
  responseDisposition?: string;
  responseType?: string;
  virtualHostedStyle?: boolean;
}

export interface PagesTypes {
  PAGES: 'PAGES';
  PAGES_START: 'PAGES_START';
  ALL_PAGES_SUCCESS: 'ALL_PAGES_SUCCESS';
  PAGES_SUCCESS: 'PAGES_SUCCESS';
  PAGES_ERROR: 'PAGES_ERROR';
  PAGE_CREATED_SUCCESS: 'PAGE_CREATED_SUCCESS';
  PAGE_CREATED_ERROR: 'PAGE_CREATED_ERROR';
  PAGES_CLEAN_UP: 'PAGES_CLEAN_UP';
}

const { Types, Creators } = createActions<PagesTypes>({
  pages: ['data'],
  pagesStart: null,
  allPagesSuccess: ['data'],
  pagesSuccess: ['data'],
  pagesError: ['error'],
  pageCreatedSuccess: ['data'],
  pageCreatedError: ['error'],
  pagesCleanUp: null,
});

export { Types };

export default Creators;
