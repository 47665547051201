import { combineReducers } from 'redux';
import donationReducer from './donation/reducers';
import donorReducer from './donor/reducers';
import pagesReducer from './pages/reducers';
import recipientsReducer from './recipients/reducers';
import summaryReducer from './summary/reducers';
import transactionReducer from './transaction/reducers';
import userReducer from './user/reducers';

export default combineReducers({
  donation: donationReducer,
  donor: donorReducer,
  pages: pagesReducer,
  recipients: recipientsReducer,
  summary: summaryReducer,
  transaction: transactionReducer,
  user: userReducer,
});
